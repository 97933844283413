import { Typography, Box, makeStyles, Link } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: "64px 64px 64px 48px",
    marginBottom: "32px",
    gap: "48px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "24px",
      padding: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "initial",
    },
  },
}))

const Service = ({ title, icon, description, button }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container} border="1px solid #222">
      <Box>
        <img
          src={icon}
          alt={title}
          style={{ maxHeight: "64px", maxWidth: "64px" }}
        />
      </Box>
      <Box maxWidth="800px">
        <Typography
          variant="h3"
          style={{ marginBottom: "16px", fontWeight: "700" }}
        >
          {title}
        </Typography>
        <Typography variant="body1">
          {description}
          <Link href="mailto:contact@digicpictures.com">{button}</Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Service
