import { Box, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "55vw",
    paddingTop: "122px",
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      height: "90vh",
    },
  },
  videoContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    paddingTop: "122px",
    transition: "all 800ms ease",
    filter: "brightness(0.7)",

    [theme.breakpoints.down("sm")]: {
      height: "90vh",
    },
    minWidth: "100%",
    objectFit: "cover",
  },
  logo: {
    filter: "drop-shadow(4px 4px 8px #000b)",
    width: "300px",
    transition: "filter 0.3s linear",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
}))

export const ShowReel = ({ videoMp4, videoWebm, posterImage, children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        className={classes.videoContainer}
        poster={posterImage}
      >
        <source src={videoMp4} type="video/mp4"></source>
        <source src={videoWebm} type="video/webm"></source>
      </video>
      <Box position={"relative"}>{children}</Box>
    </Box>
  )
}
