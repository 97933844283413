import {
  Box,
  makeStyles,
  Typography,
  CardMedia,
  Button,
} from "@material-ui/core"
import React from "react"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100vw",
    height: "70vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "40vh",
      maxHeight: "400px",
    },
  },
  title: {
    maxWidth: "900px",
    margin: "24px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  paragraph: {
    maxWidth: "800px",
    margin: "24px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#222e",
    width: "100%",
    maxWidth: "1000px",
    marginTop: "60px",
    padding: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      marginTop: "0px",
      padding: theme.spacing(5),
      backgroundColor: "#222b",
    },
  },
  reelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "48px 0",
  },
}))

const TextSection = ({
  backgroundImage,
  title,
  description,
  buttonLink,
  buttonText,
}) => {
  const classes = useStyles()
  return (
    <CardMedia
      className={classes.heroContainer}
      image={backgroundImage}
      id="section"
    >
      <Box className={classes.textBox}>
        <Typography
          variant="h1"
          align="center"
          color="primary"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant="h4" align="center" className={classes.paragraph}>
          {description}
        </Typography>
        {buttonText && (
          <Link
            to={buttonLink}
            style={{
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              style={{
                marginTop: "16px",
              }}
            >
              {buttonText}
            </Button>
          </Link>
        )}
      </Box>
    </CardMedia>
  )
}

export default TextSection
