import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Footer } from "./footer"
import { Menu } from "./menu"
import { Navbar } from "../navbar/navbar"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "./seo"

const LayoutStudios = ({ children, mainPage }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      strapi {
        studioPage {
          StudioLogo {
            url
          }
        }
      }
    }
  `)

  const menuLinks = [
    { name: "Home", link: "hero" },
    //{ name: "Introduction reel", link: "intro" },
    { name: "Our services", link: "services" },
  ]

  return (
    <main>
      <CssBaseline />
      <SEO title="DIGIC Studios" />
      <Navbar
        setMenuOpen={setMenuOpen}
        logo={data.strapi.studioPage.StudioLogo.url}
        to="/services"
      />
      {children}
      <Menu
        isOpen={isMenuOpen}
        close={() => setMenuOpen(false)}
        menuLinks={menuLinks}
      />
      <Footer />
    </main>
  )
}

LayoutStudios.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutStudios
