import { Typography, makeStyles, Box, Button } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "react-scroll"
import React from "react"
import LayoutStudios from "../../components/layout/layout-studios"
//import { EmbedVideo } from "../../components/utils/embed-video"
import Service from "../../components/studios/service"
import TextSection from "../../components/text-section"
import { ShowReel } from "../../components/homepage/show-reel"
import ShowReelVideoMp4 from "../../videos/studios.mp4"
import ShowReelVideoWebm from "../../videos/studios.webm"
import StudioShowreelPoster from "../../images/studio_showreel_poster.jpg"
import WorksGrid from "../../components/works/works-grid"

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100vw",
    height: "60vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "40vh",
      height: "initial",
    },
  },
  serviceGrid: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    margin: "24px",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  title: {
    maxWidth: "900px",
    margin: "64px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "32px 0",
    },
  },
  paragraph: {
    maxWidth: "800px",
    margin: "30px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#222e",
    maxWidth: "800px",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5),
      backgroundColor: "#222b",
    },
  },
  reelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 0",
      padding: "16px 24px",
    },
  },
  heroLogo: {
    width: "240px",
    marginBottom: "16px",
    filter: "drop-shadow(4px 4px 8px #000b)",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      marginBottom: "8px",
    },
  },
  heroContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "3",
    width: "100%",
    padding: theme.spacing(4),
    height: "100%",
  },
  heroDescription: {
    maxWidth: "700px",
    margin: "32px 0",
    filter: "drop-shadow(2px 2px 2px #000)",
    [theme.breakpoints.down("sm")]: { fontSize: "14px", margin: "24px 0" },
  },
  button: {
    boxShadow: "2px 2px 4px #0008",
    [theme.breakpoints.down("sm")]: {
      height: "42px",
      fontSize: "14px",
    },
  },
}))

const StudiosHomePage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        studioPage {
          StudioLogo {
            url
          }
          StudioLead
          StudioButton
          WhoTitle
          WhoDecsription
          WhoBg {
            url
            imageFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          StudioServicesTitle
          StudioServicesCard {
            Icon {
              url
            }
            Title
            Description
            Button
          }
        }
        studiosWorks(sort: "year_of_creation:desc") {
          id
          year_of_creation
          name
          type
          thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  console.log("data", data)

  return (
    <LayoutStudios mainPage>
      <ShowReel
        videoMp4={ShowReelVideoMp4}
        videoWebm={ShowReelVideoWebm}
        posterImage={StudioShowreelPoster}
        id="home"
      >
        <Box className={classes.heroContentWrapper}>
          <img
            src={data.strapi.studioPage.StudioLogo.url}
            alt="pictures-logo"
            className={classes.heroLogo}
            id="home"
          />
          <Typography
            variant="h3"
            align="center"
            className={classes.heroDescription}
          >
            {data.strapi.studioPage.StudioLead}
          </Typography>
          <Link to="section" smooth={true}>
            <Button variant="contained" className={classes.button}>
              {data.strapi.studioPage.StudioButton}
            </Button>
          </Link>
        </Box>
      </ShowReel>
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextSection
          backgroundImage={data.strapi.studioPage.WhoBg.url}
          title={data.strapi.studioPage.WhoTitle}
          description={data.strapi.studioPage.WhoDecsription}
          style={{ minHeight: "545px" }}
          id="who"
        />
        <Box className={classes.reelContainer}>
          <Typography variant="h1" className={classes.title} id="services">
            {data.strapi.studioPage.StudioServicesTitle}
          </Typography>
          <Box className={classes.serviceGrid}>
            {data.strapi.studioPage.StudioServicesCard.map(
              ({ Icon, Title, Description, Button }) => (
                <Service
                  title={Title}
                  description={Description}
                  icon={Icon.url}
                  button={Button}
                />
              )
            )}
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.reelContainer}
        style={{ maxWidth: "1200px", margin: "64px auto" }}
      >
        <Typography variant="h1" className={classes.title} id="services">
          Projects
        </Typography>
        <WorksGrid data={data.strapi.studiosWorks} previewPath="studios/" />
      </Box>
    </LayoutStudios>
  )
}

export default StudiosHomePage
